// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"966b264d284b503bc4c5f0e49f8431b0b0106540"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

const defaultSampleRate = 0.1; // 10%;
const criticalSampleRate = 0.25; // 25%

Sentry.init({
  dsn: "https://3734aee1489adb3462497d2f13b657ba@o4506618145538048.ingest.us.sentry.io/4507505139056640",

  tracesSampler: ({ name }) => {
    // ? add specific transactions here if they should have higher/lower sample rates than the default
    if (name.includes("api/document")) {
      // Drop this completely by setting its sample rate to 0%
      return criticalSampleRate;
    }

    // ? otherwise this is the default rate
    return defaultSampleRate;
  },

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // record 100% of sessions, no matter what
  replaysSessionSampleRate: 1.0,

  // record 100% of sessions where there's an error
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,

      // ? these 2 options require V7.50.0 or higher
      networkDetailAllowUrls: ["/api"], // allow req/res body logging for all api routes
      networkDetailDenyUrls: ["/api/auth", "/api/user"], // but exclude these (likely sensitive) ones
    }),
  ],
});
