const colorTheme = {
  lightBeige: "#fdf5e7",
  beige: "#fcefd9",
  lightOrange: "#f0a74a",
  veryDarkOrange: "#8d550c",
  orange: "#eb8b2d",
  darkOrange: "#d27314",
  lightRed: "#e88064",
  red: "#e05630",
  darkRed: "#c8431e",
  lightYellow: "#fee66c",
  yellow: "#fed81b",
  darkYellow: "#e4be01",
  lightGreen: "#a7d392",
  green: "#7abc5b",
  darkGreen: "#415436",
  veryDarkGreen: "#181f14",
  lightGray: "#ebebeb",
  gray: "#737373",
  darkGray: "#423e3e",
  darkPurple: "#28243D",
  lightPurple: "#3D3759",
  darkGrey: "#262526",
  lightGrey: "#404040",
  lightDisabledGrey: "rgba(58, 53, 65, 0.38)",
  darkDisabledGrey: "rgba(253, 245, 231, 0.38)",
};

const whiteColor = "#FFF";

const overrides = {
  button: {
    light: colorTheme.orange,
    main: colorTheme.darkOrange,
    dark: colorTheme.veryDarkOrange,
    contrastText: whiteColor,
  },
};

export { colorTheme, overrides };
