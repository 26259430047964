import * as Sentry from "@sentry/nextjs";
import { useSession } from "next-auth/react";
import { createContext, useEffect, useState } from "react";
import { getUserByEmail } from "src/utilities/client/CRUD/user";

export type UserContextType = {
  user: any;
  setUser: (user: any) => void;
  retrieveUser: (email: string) => Promise<void>;
};

interface forms {
  [key: string]: boolean;
}

export type FormsContextType = {
  forms: forms;
  setForms: (forms: forms) => void;
};

export type TopbarHeaderType = {
  topbarHeader: string;
  setTopbarHeader: (header: string) => void;
};

export type CurrentCompanyContextType = {
  currentCompany: { companyName: string; id: string };
  setCurrentCompany: (currentCompany: { companyName: string; id: string }) => void;
};

export type CommentsType = {
  savedComments: Array<{ id: string; comment?: any; commentId?: string; reply?: any }>;
  setSavedComments: (comments: Array<{ id: string; comment?: any; commentId?: string; reply?: any }>) => void;
};

export interface Field {
  id: string;
  label: string;
  hide?: boolean;
}

export interface Sort {
  field: string;
  order: "asc" | "desc";
}

export interface TablePreferenceType {
  id: string;
  fields: Field[];
  sort?: Sort;
}

export interface UserTablePreference {
  [key: string]: TablePreferenceType;
}

export type TablePreferencesType = {
  tablePreferences: UserTablePreference;
  setTablePreferences: (tablePreferences: UserTablePreference) => void;
};

export type RetrievedTablePreferencesType = {
  retrievedTablePreferences: boolean;
  setRetrievedTablePreferences: (retrievedTablePreferences: boolean) => void;
};

export const UserContext = createContext<UserContextType | null>(null);
export const TablePreferencesContext = createContext<TablePreferencesType | null>(null);
export const RetrievedTablePreferencesContext = createContext<RetrievedTablePreferencesType | null>(null);

type Props = {
  children: JSX.Element;
  setStoreUser: (user: any) => void;
  tablePreferenceState: {
    tablePreferences: any;
    setTablePreferences: (tablePreferences: any) => void;
  };
};

export default function Store({ children, setStoreUser, tablePreferenceState }: Props) {
  const [user, setUser] = useState<any>({});
  const { data: session } = useSession();
  const [tablePreferences, setTablePreferences] = useState<UserTablePreference>({});
  const [retrievedTablePreferences, setRetrievedTablePreferences] = useState(false);

  const refresh = () => {
    setUser({});
    setTablePreferences({});
  };

  const retrieveUser = async (email: string) => {
    const userObj = await getUserByEmail(email);
    setUser(userObj);

    Sentry.setUser({ id: user.id, email: user?.email });
  };

  useEffect(() => {
    if (!user) return;
    setStoreUser(user);
  }, [user]);

  useEffect(() => {
    if (session === null) {
      refresh();
      return;
    }

    if (session?.user?.email) {
      retrieveUser(session.user.email);
    }
  }, [session]);

  useEffect(() => {
    setTablePreferences(tablePreferenceState.tablePreferences.preferences);
  }, [tablePreferenceState.tablePreferences.preferences]);

  useEffect(() => {
    setRetrievedTablePreferences(tablePreferenceState.tablePreferences.retrieved);
  }, [tablePreferenceState.tablePreferences.retrieved]);

  useEffect(() => {
    if (!retrievedTablePreferences) return;

    // once the preferences have been set inside Store - set them to retrieved in _app.tsx
    tablePreferenceState.setTablePreferences({
      ...tablePreferenceState.tablePreferences,
      stored: true,
    });
  }, [retrievedTablePreferences]);

  return (
    <UserContext.Provider value={{ user, setUser, retrieveUser }}>
      <TablePreferencesContext.Provider value={{ tablePreferences, setTablePreferences }}>
        <RetrievedTablePreferencesContext.Provider value={{ retrievedTablePreferences, setRetrievedTablePreferences }}>{children}</RetrievedTablePreferencesContext.Provider>
      </TablePreferencesContext.Provider>
    </UserContext.Provider>
  );
}
