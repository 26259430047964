// ** Type import
import { VerticalNavItemsType } from "src/page-layouts/types";
import { Actions, PermissionSubject } from "src/configs/permissions";

const navigation = (): VerticalNavItemsType => {
  return [
    {
      title: "Home",
      path: "/home",
      subject: "home-page" as PermissionSubject,
      action: "read" as Actions,
      icon: "mdi:home-outline",
    },
    {
      title: "Customers",
      path: "/customers",
      subject: "customers" as PermissionSubject,
      action: "read" as Actions,
      icon: "ion:people-outline",
    },
    {
      title: "Projects",
      path: "/projects",
      subject: "projects" as PermissionSubject,
      action: "read" as Actions,
      icon: "ion:grid-outline",
    },
  ];
};

export default navigation;
